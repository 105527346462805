import React from "react"
import Layout from "../components/layout"
import SEO from "../components/Seo"
import parser from "react-html-parser"
import "../css/privacy-policy.css"

const Privacy = ({ data }) => {
  let privtitle = data.wordpressPage.title
  let privcontent = data.wordpressPage.content
  let yoast = data.wordpressPage.yoast_head

  return (
    <>
      <Layout>
        <SEO title={{ yoast }} />
        <section className="privacy-policy" id="privacy-policy">
          <div className="container">
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-10">
                <div className="col-md-12 ">
                  <h1 className="priv-title">{privtitle}</h1>
                  {parser(privcontent)}
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}
export default Privacy

export const privacyquery = graphql`
  {
    wordpressPage(wordpress_id: { eq: 3 }) {
      title
      content
      yoast_head
    }
  }
`
